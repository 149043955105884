import API from "../../configs/api";
import apiMethod from "@utility/ApiMethod";

export const loginGoogle = async (token) => {
  return apiMethod.post(API.USER_LOGIN_GOOGLE, {
    token,
    provider: 'GOOGLE'
  });
};

export const signIn = async (payload) => {
  return apiMethod.post(API.USER_LOGIN, payload);
};

export const verify = async (payload) => {
  try {
    const res = await apiMethod.post(API.USER_VERIFY, payload);
    if (res.status >= 200 && res.status < 300) {
      return res.data;
    } else {
      Promise.resolve();
    }
  } catch (e) {
    console.log("Error", e);
    Promise.resolve(e);
  }
};

export const register = async (payload) => {
  const response = await apiMethod.post(API.USER_REGISTER, payload);
  return response;
};

export const refreshVerifyEmail = async (payload) => {
  return apiMethod.post(API.USER_REFRESH_VERIFY_EMAIL, payload);
};

export const login = (payload) => {
  const response = apiMethod.post(API.ADMIN_LOGIN_API, payload);
  if (response.status < 200 || response.status > 300) {
    return response.data;
  } else {
    return response;
  }
};

export const resetPassword = (payload) => {
  const response = apiMethod.post(API.RESET_PASSWORD, payload);
  if (response.status < 200 || response.status > 300) {
    return response.data;
  } else {
    return response;
  }
};

export const refreshAccessToken = () => {
  return apiMethod.get(API.REFRESH_TOKEN);
};
