'use strict'

export default {
  dev: {
    API_URL: 'https://dev-api.bigbom.net/v3',
  },
  uat: {
    API_URL: 'https://uat-api.bigbom.net/v3',
  },
  beta: {
    API_URL: 'https://api.bigx.vn/v3',
  },
  production: {
    API_URL: 'https://api.bigbom.com/v3',
  },
}
