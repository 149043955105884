import apiMethod from "@utility/ApiMethod";
import API from "@src/config/api";

export const getProfileUser = async (payload) => {
	return apiMethod.get(API.GET_PROFILE_USER, {
		// headers: payload.token,
	});
};

export const getIdTikTok = async (payload) => {
	return apiMethod.get(`${API.GET_ID_TIKTOK}?userId=${payload?.id}`);
};
