import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
import initSagas from "./store/initSagas";
import initReducer from "./store/initReducer";

export default {
  name: "Authenticate",
  dir: "Authenticate",
  pathRoot: "",
  routes: [
    {
      url: "",
      component: "Page/home",
      layout: "BlankLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Home | Tiktok",
        titleI18n: "Auth:title",
        exact: true,
      },
    },

    {
      url: "verify",
      component: "Page/verify-email",
      layout: "BlankLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Home | Tiktok",
        titleI18n: "Auth:title_register",
        exact: true,
      },
    },

    {
      url: "login",
      component: "Page/login",
      layout: "BlankLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Home | Tiktok",
        titleI18n: "Auth:title",
        exact: true,
      },
    },
    {
      url: "forgot-password",
      component: "Page/forgot-password",
      layout: "BlankLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Home | Tiktok",
        titleI18n: "Auth:forgot_password_title",
        exact: true,
      },
    },
    
    {
      url: "admin/login",
      component: "Page/login/LoginAdmin.js",
      layout: "BlankLayout",
      meta: {
        authRoute: true,
      },
      props: {
        title: "Home | Tiktok",
        titleI18n: "Auth:login_admin_title",
        exact: true,
      },
    },
  ],
  lang: { vi: intlMessagesVi, en: intlMessagesEN },
  isAuthenticate: false,
  sagas: initSagas,
  redux: initReducer,
};
