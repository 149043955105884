import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
// import initSagas from "./store/initSagas";
// import initReducer from "./store/initReducer";

export default {
	name: "Admin",
	dir: "Admin",
	pathRoot: "",
	routes: [
		{
			url: "admin-dashboard",
			component: "Page/home",
			layout: "VerticalLayout",
			meta: {
				authRoute: true,
			},
			props: {
				title: "DashBoard | Tiktok BigX",
				titleI18n: "Admin:title",
				exact: true,
			},
		},
		{
			url: "user",
			component: "Page/ManagerUser",
			layout: "VerticalLayout",
			meta: {
				authRoute: true,
			},
			props: {
				title: "User | Tiktok BigX",
				titleI18n: "Admin:title",
				exact: true,
			},
		},
	],
	lang: { vi: intlMessagesVi, en: intlMessagesEN },
	isAuthenticate: true,
	role: ["ADMIN"]
	//   sagas: initSagas,
	//   redux: initReducer,
};
