import axiosCreate  from '../utility/ApiMethod'

const auth = {
    register (data) {
        const url = '/users';
        return axiosCreate.post(url, data);
    },
    verifyEmail (data) {
        const url = '/users/authentication/verifications';
        return axiosCreate.post(url, data);
    },
    getCodeOTP (data) {
        const url = '/users/authentication/passwords';
       try {
        return axiosCreate.post(url, data);
       } catch (error) {
           console.log(error)
       }
    },
    login (data) {
        const url = '/users/authentication';
        return axiosCreate.post(url, data);
    },
    logout () {
        const url = '/users/authentication';
        return axiosCreate.delete(url, {isKeepCurrent: localStorage.getItem('rememberMe')});
    }
}

export default auth