let BASE_URL_API_ENV = "https://dev-api.bigbom.net/v3";

switch (process.env.REACT_APP_ENV) {
	case 'dev':
		BASE_URL_API_ENV = "https://dev-api.bigbom.net/v3";
		break;
	case 'uat':
		BASE_URL_API_ENV = "https://uat-api.bigbom.net/v3";
		break;
	case 'beta':
		BASE_URL_API_ENV = "https://beta-api.bigbom.net/v3";
		break;
	case 'production':
		BASE_URL_API_ENV = "https://api.bigbom.com/v3";
		break;
	default:
		console.log('Môi trường không hợp lệ !!');
		break;
}
export default {
	BASE_URL_API: BASE_URL_API_ENV,
	GET_PROFILE_USER: "/users/profile",
	GET_ID_TIKTOK: "/ad-accounts",
	GET_OTP_CODE : '/users/authentication/passwords',
	LOGOUT_USER: '/users/authentication',
};
