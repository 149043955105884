import { select, call, put, takeLatest } from "redux-saga/effects";
import { actions, types } from "./reducer";
import * as service from "./service";

const getInfoUser = function* (payload) {
	try {
		yield put(actions.setLoading(true));
		const { data } = yield call(service.getProfileUser);
		// const { data2 } = yield call(service.getIdTikTok);
		console.log("res profile", data);
		yield put(actions.setInfoData({ ...data?.data, ...payload }));
	} catch (error) {
		console.log("error", error);
		yield put(actions.setInfoData({}));
	} finally {
		yield put(actions.setLoading(false));
	}
};

export default function* () {
	yield takeLatest(types.GET_INFO_USER, getInfoUser);
}
