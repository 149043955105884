import React, { lazy, useEffect, useState } from "react";

//** Load App
const LazyApp = lazy(() => import("./App"));

/*Hooks*/
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";

/*Helper*/
import moment from "moment";

/*Redux*/
import { getLanguage } from "@store/common/selectors";

const InitApp = ({ DefaultRoute, listRoutes, listNav }) => {
  const { i18n } = useTranslation();
  const [loaded, setLoaded] = useState(false);

  /*Selectors*/
  const lang = useSelector((state) => getLanguage(state));

  useEffect(() => {
    i18n.changeLanguage(lang);
    moment.locale(lang);
  }, [lang]);

  return (
    <LazyApp DefaultRoute={DefaultRoute} Routes={listRoutes} Nav={listNav} />
  );
};

export default InitApp;
