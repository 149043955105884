import intlMessagesEN from "./i18n/localization/en.json";
import intlMessagesVi from "./i18n/localization/vi.json";
// import initSagas from "./Store/initSagas";
// import initReducer from "./Store/initReducer";

export default {
	name: "Home",
	dir: "Home",
	pathRoot: "",
	routes: [
		{
			url: "",
			component: "Page/home",
			layout: "VerticalLayout",
			meta: {
				authRoute: true,
			},
			props: {
				title: "Home | Tiktok",
				titleI18n: "Home:title",
				exact: true,
			},
		},
		{
			url: "change-password",
			component: "Page/change-password",
			layout: "VerticalLayout",
			meta: {
				authRoute: true,
			},
			props: {
				title: "Home | Tiktok",
				titleI18n: "Home:title",
				exact: true,
			},
		},
		{
			url: "payment",
			component: "Page/transaction-history",
			layout: "VerticalLayout",
			meta: {
				authRoute: true,
			},
			props: {
				title: "Home | Tiktok",
				titleI18n: "Home:title",
				exact: true,
			},
		},
		{
			url: "my-account",
			component: "Page/my-account",
			layout: "VerticalLayout",
			meta: {
				authRoute: true,
			},
			props: {
				title: "Home | Tiktok",
				titleI18n: "MyAccount:title",
				exact: true,
			},
		},
	],
	lang: { vi: intlMessagesVi, en: intlMessagesEN },
	isAuthenticate: true,
	// role: ["REFERRER"],
	// sagas: initSagas,
	// redux: initReducer,
};
