import { createAction, handleActions } from "redux-actions";
import storage from "redux-persist/lib/storage";
import { persistReducer } from "redux-persist";

export const types = {
  GET_INFO_USER: "DSHD/user/GET_INFO_USER",
  SET_USER_DATA: "DSHD/user/SET_USER_DATA",
  UPDATE_USER_DATA: "DSHD/user/SET_USER_DATA",
  SET_LOADING: "DSHD/user/SET_LOADING",
  REFRESH_TOKEN: "DSHD/user/REFRESH_TOKEN",
};

export const actions = {
  setInfoData: createAction(types.SET_USER_DATA),
  getInfoUser: createAction(types.GET_INFO_USER),
  setLoading: createAction(types.SET_LOADING),
  refreshTokenAction: createAction(types.REFRESH_TOKEN),
};

const defaultState = {
  userData: {},
  loading: false,
  error: "",
  generalInfoMenu: null,
};

const UserReducer = handleActions(
  {
    [types.SET_USER_DATA]: (state, { payload }) => {
      return { ...state, userData: { ...payload } };
    },
    [types.UPDATE_USER_DATA]: (state, { payload }) => {
      return { ...state, userData: { ...state.userData, ...payload } };
    },
    [types.REFRESH_TOKEN]: (state, { payload }) => {
      return { ...state, userData: { ...state.userData, token: payload } };
    },
    [types.SET_LOADING]: (state, { payload }) => {
      return { ...state, loading: payload };
    },
  },
  defaultState
);

const persistConfig = {
  key: "user",
  storage,
  blacklist: ["loading", "error"],
};

export default persistReducer(persistConfig, UserReducer);
